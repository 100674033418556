.card-style {
    width: 32%;
    height: auto;
    align-self: flex-start;
}



.img-height{
    height: 300px;
}

.discountCSS{
    /* margin-left: 5px; */
    font-size: 16px;
    font-weight: 500;
    color: #388e3c;
    vertical-align: middle; 
}
.offerPrice{
    font-size: 130%;
    font-weight: 500;
    font-family: "Times New Roman", Times,
}

.price{
    font-size: 100%;
    /* margin-left: 5px; */
    vertical-align: middle;
    color: #878787;
    text-decoration: line-through;
}
.image-main-grid {
    columns: 3;
    column-gap: 10px;
}

.image-grid {
    display: inline-block;
    margin-bottom: 5px;
    position: relative;

}

@media (max-width: 767px) {
    .image-main-grid {
        columns: 1;
    }
}
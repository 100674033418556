.dot {
    height: 50px;
    width: 50px;
    background-color: #ece224;
    border-radius: 50%;
    display: inline-block;
    display: flex; /* or inline-flex */
    align-items: center; 
    justify-content: center;
    font-weight: bold;
    color: rgb(215, 48, 48);
    margin-top: -51px;
    align-self: end;
  }